import axios from 'axios'
import { Text } from 'fitify-ui'
import { EMAIL_REGEXP } from 'fitify-utils/src/validations'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useForm } from 'react-hook-form'

import Container from '../../Container/Container'
import Section from '../../Section/Section'

import {
  ButtonLoader,
  StyledNewsletter,
  StyledNewsletterButton,
  StyledNewsletterCopy,
  StyledNewsletterEmailInput,
  StyledNewsletterEmailSuccessMessage,
  StyledNewsletterErrorMessage,
  StyledNewsletterForm,
  StyledNewsletterFormContainer,
} from './Newsletter.Styled'

type NewsletterFormInputs = {
  email: string
}

const NEXT_PUBLIC_LIST_ID = process.env.NEXT_PUBLIC_LIST_ID

interface NewsletterProps {
  blockText: string
  emailPlaceholderText: string
  submitButtonText: string
  successMessage: string
  errorMessage: string
}

const Newsletter = ({
  blockText,
  emailPlaceholderText,
  submitButtonText,
  successMessage,
  errorMessage,
}: NewsletterProps) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<NewsletterFormInputs>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFaded, setIsFaded] = useState<boolean>(false)
  const timer = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (!isSubmitSuccessful) {
      setIsFaded(false)
      return
    }

    if (!isLoading) setIsFaded(true)

    timer.current = setTimeout(() => {
      setIsFaded(false)
    }, 5000)

    return () => {
      clearTimeout(timer.current)
    }
  }, [isSubmitSuccessful, isLoading])

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/handleSendgridNewsletterSubscribe`,
        {
          list_id: NEXT_PUBLIC_LIST_ID,
          email: data.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.status === 200) {
        setValue('email', '')
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setError('email', {
        type: 'server',
        message: t('email_sign_up_invalid_credentials_message'),
      })
    }
  })

  return (
    <Section id={'newsletter'}>
      <Container>
        <Fade triggerOnce>
          <StyledNewsletter>
            <StyledNewsletterCopy>
              <Text
                as={'p'}
                color={'Gray600'}
                variant={'newsletter-title'}
                html={t(blockText)}
              />
            </StyledNewsletterCopy>
            <StyledNewsletterFormContainer>
              <StyledNewsletterForm
                onSubmit={onSubmit}
                isSuccess={isSubmitSuccessful}
                hasError={!!errors.email}
              >
                <StyledNewsletterEmailInput
                  type="text"
                  placeholder={t(emailPlaceholderText)}
                  {...register('email', {
                    required: true,
                    pattern: EMAIL_REGEXP,
                  })}
                  isSuccess={isFaded}
                  disabled={isFaded}
                />
                {isFaded ? (
                  <StyledNewsletterEmailSuccessMessage isSuccess={isFaded}>
                    {t(successMessage)}
                  </StyledNewsletterEmailSuccessMessage>
                ) : null}
                {isLoading ? (
                  <ButtonLoader />
                ) : (
                  <StyledNewsletterButton type="submit" isSuccess={isFaded}>
                    {t(submitButtonText)}
                  </StyledNewsletterButton>
                )}
              </StyledNewsletterForm>
              {errors.email && (
                <StyledNewsletterErrorMessage>
                  {errors.email.message || t(errorMessage)}
                </StyledNewsletterErrorMessage>
              )}
            </StyledNewsletterFormContainer>
          </StyledNewsletter>
        </Fade>
      </Container>
    </Section>
  )
}

export default Newsletter
